// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format as dateFmt } from 'date-fns';

import Modal from '@shared/Modal/Modal';
import Button, { buttonConstants } from '@shared/Button';
import DatePickerField from '_common/components/DateTimePicker/components/DatePickerField';

import PaymentVentilatedService, { type PaymentTransferServiceData } from '@payment/services/PaymentVentilatedService';
import DateService from '_common/services/DateService';
import { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';

import type { ErrorProps, Error } from '@core/types';

import { FORMAT_DATE_API } from '@app/constants/dates';

type Props = {
  isActiveModalAccountingExport: boolean,
  toggleModalAccountingExport: () => void,
};

const ModalAccountingExport = (props: Props): React$Node => {
  const { isActiveModalAccountingExport, toggleModalAccountingExport } = props;
  const { t, i18n: { language } } = useTranslation();

  const [ startDate, setStartDate ] = useState<Date | null>(null);
  const [ endDate, setEndDate ] = useState<Date | null>(null);
  const [ errors, setErrors ] = useState<Error[]>([]);

  const handleUpdateDate = (data: PaymentTransferServiceData): void => {
    setErrors(data.errors);
  };

  useEffect(() => PaymentVentilatedService.onChange(handleUpdateDate), []);

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
    setErrors([]);
  }, [isActiveModalAccountingExport]);

  const handleChange = useCallback((date: moment$Moment, type: string): void => {
    let dateFormatted = date ? new Date(String(date)) : null;
    if (type === 'startDate') {
      setStartDate(dateFormatted);
    } else if (type === 'endDate') {
      setEndDate(dateFormatted);
    }
  }, [setStartDate, setEndDate]);

  const handleSubmit = useCallback((): void => {
    let errorsTmp = [];

    if (!startDate) {
      errorsTmp.push({ propertyPath: 'startDate', code: ERROR_IS_BLANK, message: '' });
    }

    if (!endDate) {
      errorsTmp.push({ propertyPath: 'endDate', code: ERROR_IS_BLANK, message: '' });
    }

    setErrors(errorsTmp);

    if (startDate && endDate) {
      const startDateFormatted = dateFmt(new Date(startDate), FORMAT_DATE_API);
      const endDateFormatted = dateFmt(new Date(endDate), FORMAT_DATE_API);
      const params = {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      };
      PaymentVentilatedService.FetchAccoutingExport(params)
        .then(toggleModalAccountingExport);
    }
  }, [startDate, endDate, setErrors]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error: Error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          labelProps: { className: 'has-text-danger' },
          error: {
            text: error.message,
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors, language]);

  const footerModalAccountingExport: React$Node = useMemo((): React$Node => (
    <footer className="modal-accounting-export-footer">
      <Button
        type={ buttonConstants.PRIMARY }
        className="button-modal"
        onClick={ handleSubmit }
      >
        { t('common.validate') }
      </Button>
    </footer>
  ), [handleSubmit, language]);

  return (
    <Modal
      isActive={ isActiveModalAccountingExport }
      isClipped
      onClose={ toggleModalAccountingExport }
      title={ t('groupClasses.accountingExport') }
      footer={ footerModalAccountingExport }
      modalContentClassName="modal-accounting-export-card"
    >
      <div className="modal-accounting-export">
        <p className="modal-accounting-export-text">{ t('groupClasses.modalaccountingExportText') }</p>
        <DatePickerField
          id="startDate"
          name="startDate"
          value={ startDate ?? null }
          label={ t('groupClasses.modalStartDate') }
          onChange={ (date) => handleChange(date, 'startDate') }
          color={ errorsProps.startDate?.color }
          labelProps={ errorsProps.startDate?.labelProps }
          helpProps={ errorsProps.startDate?.error }
          required
        />
        <div id="date-popup" />
        <DatePickerField
          id="endDate"
          name="endDate"
          value={ endDate ?? null }
          label={ t('groupClasses.modalEndDate') }
          minDate={ startDate ? DateService.addDayToDate(startDate, 1) : null }
          onChange={ (date) => handleChange(date, 'endDate') }
          isDisabled={ !startDate }
          color={ errorsProps.endDate?.color }
          labelProps={ errorsProps.endDate?.labelProps }
          helpProps={ errorsProps.endDate?.error }
          required
        />
      </div>
    </Modal>
  );
};

export default ModalAccountingExport;
