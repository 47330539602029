// @flow
import type { AxiosXHR } from 'axios';
import FileSaver from 'file-saver';

import ObjectHelpers from '@helpers/ObjectHelpers';

class FileHelpers {
  /**
   * @type Static
   * saveFile
   * @param {AxiosXHR<any>} apiResponse
   * @returns {void}
   */
  static saveFile(apiResponse: AxiosXHR<any>): void {
    const { headers, data } = apiResponse;
    const contentDisposition = ObjectHelpers.parseCSV(headers?.['content-disposition']);
    const fileName = contentDisposition?.filename || 'fileName';
    const contentType = (headers && 'content-type' in headers)
      ? headers['content-type']?.split(';')[0]
      : 'text/plain';
    const content = new Blob([data], {
      type: contentType ?? 'text/plain',
    });
    FileSaver.saveAs(content, fileName);
  }
}

export default FileHelpers;
