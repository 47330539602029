// @flow
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { User } from '@user/types';

import Button, { buttonConstants } from '@shared/Button';
import Toast from '_common/services/Toast/Toast';
import ModalArchivePeriods from '@groupClass/components/ModalArchivePeriods.jsx';
import ModalAccountingExport from '@groupClass/components/ModalAccountingExport';

import GroupClassesPeriodArchiveService, { type GroupClassesPeriodArchiveServiceData } from '@groupClass/services/GroupClassesPeriodArchiveService';
import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import RolesHelpers from '@user/helpers/RolesHelpers';

type Props = {
  children: React$Node,
};

const TitleBanner = (props: Props): React$Node => {
  const { children } = props;
  const { t } = useTranslation();

  const [ isActiveModalAccountingExport, setIsActiveModalAccountingExport ] = useState<boolean>(false);
  const [ isActiveModalArchivePeriods, setIsActiveModalArchivePeriods ] = useState<boolean>(false);
  const [ isArchivingAuthorized, setIsArchivingAuthorized ] = useState<boolean>(false);
  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  const handleUpdatePeriodArchiveService = (data: GroupClassesPeriodArchiveServiceData): void => {
    if (data.groupClassesPeriodArchives.length > 0) {
      const lastArchivingYear = data.groupClassesPeriodArchives[0].archivedAt.getFullYear();
      const currentYear = new Date().getFullYear();
      if (currentYear === lastArchivingYear) {
        setIsArchivingAuthorized(false);
      } else {
        setIsArchivingAuthorized(true);
      }
    } else {
      setIsArchivingAuthorized(true);
    }
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);
  useEffect(() => GroupClassesPeriodArchiveService.onChange(handleUpdatePeriodArchiveService), []);
  useEffect(() => {
    GroupClassesPeriodArchiveService.fetchAll();
  }, []);

  const toggleModalAccountingExport = useCallback((): void => {
    setIsActiveModalAccountingExport(!isActiveModalAccountingExport);
  }, [isActiveModalAccountingExport]);

  const toggleModalArchivePeriods = useCallback((): void => {
    setIsActiveModalArchivePeriods(!isActiveModalArchivePeriods);
  }, [isActiveModalArchivePeriods, setIsActiveModalArchivePeriods]);

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const isAdmin = useMemo((): boolean => (
    RolesHelpers.isAdmin(currentUser)
  ), [currentUser]);

  const titleBannerToggleClassName = useMemo((): string => clsx({
    'title-banner-toggle': !isAdmin,
    'toggle-admin': !!isAdmin,
  }, [isAdmin]));

  return (
    <>
      <div className="title-banner">
        <div className="title-banner-title">
          <h1>
            { t('navigation.myGroupClasses') }
          </h1>
        </div>
        <div className={ titleBannerToggleClassName }>
          { children }
          { isAdmin && (
            <div className="title-banner-button">
              <Button
                onClick={ toggleModalAccountingExport }
                type={ buttonConstants.SECONDARY }
              >
                { t('groupClasses.accountingExport') }
              </Button>
              <Button
                onClick={ toggleModalArchivePeriods }
                type={ buttonConstants.SECONDARY }
                className="title-banner-button-archive"
                isDisabled= { !isArchivingAuthorized }
              >
                { t('groupClasses.archive') }
              </Button>
            </div>
          ) }
        </div>
      </div>
      <ModalAccountingExport
        isActiveModalAccountingExport={ isActiveModalAccountingExport }
        toggleModalAccountingExport={ toggleModalAccountingExport }
      />
      <ModalArchivePeriods
        toggleModal={ toggleModalArchivePeriods }
        isActive={ isActiveModalArchivePeriods }
        showSuccessMessage= { showSuccessMessage }
      />
    </>
  );
};

export default TitleBanner;
